<template>
  <div>
    <el-container>
      <el-header>
      </el-header>
      <el-main>

        <!-- 图片介绍模块 -->
        <div class="background-image2">
          <div class="left-side" :xs="24" :sm="12" :md="12">
            <img src="../media/fakeProduct.jpg" alt="Simple Image" class="responsive-image">
          </div>
          <div class="right-side" :xs="24" :sm="12" :md="12">
            <p class="feature"><strong>功能全面，性能优良</strong></p>
            <p class="feature2"><strong>支持IO触发、ROI等各类功能，适应工作温度范围广、功耗低，性能稳定。</strong></p>
            <table class="original-table">
              <tr>
                <td>外触发</td><td>增益</td><td>曝光</td>
              </tr>
              <tr>
                <td>gamma</td><td>LUT</td><td>Binning</td>
              </tr>
              <tr>
                <td>下采样</td><td>ROI</td><td>边缘检测</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 产品概述模块 -->
        <el-row>
          <h2 class="parts-title" style="--after-width: 140px;">产品细节</h2>
          <p>博峤精心研发的工业相机系列涵盖了AG、AR、LS、AAG、AAR、ALS以及EAG、EAR等多个品类，</p>
          <p>像素范围广泛，从30万至2000万不等，全面适配GigE、10GigE、USB3.0以及Camera Link等全系列接口。</p>
          <p>这些相机均搭载了高品质的成像芯片，确保了卓越非凡的图像质量，展现了博峤在工业视觉领域的深厚造诣与精湛技艺。</p>
        </el-row>

        <!-- 产品介绍大块 -->
        <el-row :gutter="20" class="product-introduction">
          <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in productCards" :key="index">
            <!-- 点击卡片底色 -->
            <el-card 
              shadow="hover" 
              class="product-card"
              :class="{ selected: selectedCardIndex === index }"
              @click="setCameraType(item.title, index)"
            >
              <router-link :to="item.route" class="card-link">
                <div class="card-content">
                  <div class="image-container">
                    <img v-if="index === 0" src="../media/cameraBasic.jpg" alt="产品图片1" class="product-image" />
                    <img v-if="index === 1" src="../media/cameraMini.jpg" alt="产品图片2" class="product-image" />
                    <img v-if="index === 2" src="../media/cameraBasicShot.jpg" alt="产品图片3" class="product-image" />
                    <img v-if="index === 3" src="../media/cameraTotal.jpg" alt="产品图片4" class="product-image" />
                    <div v-if="index >= 4" class="no-image">暂无图片</div>
                  </div>
                  <div class="text-container">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </router-link>
            </el-card>
          </el-col>
        </el-row>



        <!-- 产品参数下载模块 -->
        <div class="product-switch-section">
          <h2 class="parts-title" style="--after-width: 220px;">{{ currentProductTitle }}</h2>
          
          <div class="content-wrapper">
            <!-- 切换按钮 -->
            <div class="switch-buttons">
              <el-button 
                :type="currentProduct === 'area' ? 'primary' : 'default'"
                @click="switchProduct('area')"
              >
                面阵相机
              </el-button>
              <el-button 
                :type="currentProduct === 'line' ? 'primary' : 'default'"
                @click="switchProduct('line')"
              >
                线扫相机
              </el-button>
              
            </div>

            <!-- 筛选器 -->
            <div class="filters-container">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.dataInterface" placeholder="接口类型" clearable>
                    <el-option label="GigE" value="GigE"></el-option>
                    <el-option label="CameraLink/LAN" value="CameraLink/LAN"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.colorType" placeholder="色彩" clearable>
                    <el-option label="黑白" value="黑白"></el-option>
                    <el-option label="彩色" value="彩色"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.productionStatus" placeholder="产品状态" clearable>
                    <el-option label="现已上线" value="现已上线"></el-option>
                    <el-option label="计划上线" value="计划上线"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-select v-model="filters.resolution" placeholder="分辨率" clearable>
                    <el-option label="100万像素以下" value="low"></el-option>
                    <el-option label="100-500万像素" value="medium"></el-option>
                    <el-option label="500万以上" value="high"></el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="5" class="filter-item">
                  <el-button type="info" @click="resetFilters">重置筛选</el-button>
                </el-col>
              </el-row>
            </div>

            <!-- 表格容器 -->
            <div class="table-container">
              <el-table 
                :data="filteredData" 
                style="width: 100%" 
                :row-class-name="tableRowClassName"
              >
                <el-table-column prop="productModel" label="产品型号" min-width="180"></el-table-column>
                <el-table-column prop="sensorModel" label="传感器型号" min-width="120"></el-table-column>
                <el-table-column prop="resolution" label="分辨率" min-width="150"></el-table-column>
                <el-table-column prop="maxFrameRate" label="最大帧率" min-width="120"></el-table-column>
                <el-table-column prop="dataInterface" label="接口" min-width="120"></el-table-column>
                <el-table-column prop="colorType" label="色彩" min-width="120"></el-table-column>
                <el-table-column prop="productionStatus" label="状态" min-width="120"></el-table-column>
                <el-table-column width="100" label="产品资料" fixed="right">
                  <template v-slot="scope">
                    <el-button 
                      type="primary" 
                      @click="goToDownload(scope.row.productModel)"
                    >
                      <el-icon><Download /></el-icon>
                      下载
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>



      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Delete, Download, Edit } from '@element-plus/icons-vue'
import './Product.css'; 
export default {
  components: {
    Edit,Delete
  },
  data() {
    return {
      currentProduct: 'area', // 默认显示面阵相机
      filters: {
        dataInterface: '',
        colorType: '',
        productionStatus: '',
        resolution: '',
        cameraType: '' 

      },
      areaData: [
        // 面阵相机系列
        //标准相机
        { CameraType:'标准相机', productModel: 'BoQ-AG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG230S-GC', sensorModel: 'IMX392', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG230S-GM', sensorModel: 'IMX392', resolution: '1920*1200', maxFrameRate: '201fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG250G-GC', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG250G-GM', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG500S-GC', sensorModel: 'IMX264', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG500S-GM', sensorModel: 'IMX264', resolution: '2448*2048', maxFrameRate: '24.2fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG1600S-GM', sensorModel: 'IMX542', resolution: '5328*3040', maxFrameRate: '52fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG1600S-GC', sensorModel: 'IMX542', resolution: '5328*3040', maxFrameRate: '52fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG510S-GM', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'标准相机', productModel: 'BoQ-AG510S-GC', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },
        
        // 功能相机
        { CameraType:'功能相机', productModel: 'BoQ-EAG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '现已上线' },
        { CameraType:'功能相机', productModel: 'BoQ-EAG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '现已上线' },

        // AI相机
        { CameraType:'AI相机', productModel: 'BoQ-AAG040S-GC', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-AAG040S-GM', sensorModel: 'IMX287', resolution: '720*540', maxFrameRate: '312fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-AAG250G-GC', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-AAG250G-GM', sensorModel: 'GMAX4002', resolution: '2048*1200', maxFrameRate: '660fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-AAG510S-GM', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '彩色', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-AAG510S-GC', sensorModel: 'IMX548', resolution: '2472*2064', maxFrameRate: '114fps', dataInterface: 'GigE', colorType: '黑白', productionStatus: '计划上线' },
      ],
      lineData: [
        // 线扫相机系列
        { CameraType:'标准相机', productModel: 'BoQ-LS4kG04-12', sensorModel: 'GL7004', resolution: '4096*1/2/3/4', maxFrameRate: '200KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        { CameraTxype:'标准相机', productModel: 'BoQ-LS8kG04-12', sensorModel: 'GL7008', resolution: '8192*1/2/3/4', maxFrameRate: '200K/100K/66K/50KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        { CameraType:'标准相机', productModel: 'BoQ-LS16kG02-12', sensorModel: 'GL3516', resolution: '16384*1/2', maxFrameRate: '120K/60KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        // 功能相机
          // null
        // AI相机
        { CameraType:'AI相机', productModel: 'BoQ-ALS4kG04-12', sensorModel: 'GL7004', resolution: '4096*1/2/3/4', maxFrameRate: '200KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-ALS8kG04-12', sensorModel: 'GL7008', resolution: '8192*1/2/3/4', maxFrameRate: '200K/100K/66K/50KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        { CameraType:'AI相机', productModel: 'BoQ-ALS16kG02-12', sensorModel: 'GL3516', resolution: '16384*1/2', maxFrameRate: '120K/60KHz', dataInterface: 'CameraLink/LAN', colorType: '黑白/彩色', productionStatus: '计划上线' },
        
      ],
      selectedSensorModel: '',
      selectedResolution: '',
      selectedMaxFrameRate: '',
      selectedColorType: '',
      selectedProductionStatus: '',
      selectedCameraType: '',

      // 添加产品卡片数据
      productCards: [
        {
          title: '标准相机',
          description: '品质系列，想你所需',
          
        },
        {
          title: '功能相机',
          description: '线扫系列，知你所意',
          
        },
        {
          title: 'AI相机',
          description: 'AI系列，解放双手',
          
        },
        {
          title: '更多产品',
          description: '更多产品，供君挑选',
          route: '/MoreProduct'
        
        }
      ],
      selectedCardIndex: null,
      currentProductTitle: '产品列表', // 新增字段用于存储当前产品列表标题
      cameraTypes: ['AI相机', '基础相机','功能相机'], // 相机类型
      selectedCameraType: '基础相机', // 默认选择的相机类型
    };
  },
  computed: {
    tableData() {
      // 根据当前选择返回对应的数据集
      return this.currentProduct === 'area' ? this.areaData : this.lineData;
    },
    filteredData() {
      let data = this.tableData;
      
      if (this.filters.dataInterface) {
        data = data.filter(item => item.dataInterface === this.filters.dataInterface);
      }
      
      if (this.filters.colorType) {
        data = data.filter(item => {
          const colors = item.colorType.split('/');
          return colors.includes(this.filters.colorType);
        });
      }
      
      if (this.filters.productionStatus) {
        data = data.filter(item => item.productionStatus === this.filters.productionStatus);
      }
      
      if (this.filters.resolution) {
        data = data.filter(item => {
          const pixels = this.calculatePixels(item.resolution);
          switch(this.filters.resolution) {
            case 'low':
              return pixels < 1000000;
            case 'medium':
              return pixels >= 1000000 && pixels < 5000000;
            case 'high':
              return pixels >= 5000000;
            default:
              return true;
          }
        });
      }
      
      // 根据相机类型进行筛选
      if (this.filters.cameraType) {
        data = data.filter(item => item.CameraType === this.filters.cameraType);
      }
      
      return data;
    }
  },
  methods: {
    calculatePixels(resolution) {
      try {
        // 处理特殊格式的分辨率，如 "4096*1/2/3/4"
        const [width, height] = resolution.split('*');
        const widthNum = parseInt(width);
        let heightNum;
        
        if (height.includes('/')) {
          // 如果高度包含分数，取第一个数字
          heightNum = parseInt(height.split('/')[0]);
        } else {
          heightNum = parseInt(height);
        }
        
        return widthNum * heightNum;
      } catch (error) {
        console.error('Resolution calculation error:', error);
        return 0;
      }
    },
    resetFilters() {
      // 只重置与相机类型无关的筛选条件
      this.filters = {
        ...this.filters, // 保留现有的筛选条件
        dataInterface: '', // 重置接口类型
        colorType: '', // 重置色彩
        productionStatus: '', // 重置产品状态
        resolution: '', // 重置分辨率
        // 不重置 cameraType，保留相机类型筛选
      };
    },
    tableRowClassName({ row, rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },



    goToDownload(productModel) {
      // 从产品型号中提取ID部分
      const id = productModel.replace('BoQ-', '')
      this.$router.push({
        path: '/download',
        query: { id }
      })
    },
    switchProduct(type) {
      this.currentProduct = type;
      this.resetFilters(); // 切换产品类型时重置筛选条件
    },
    setCameraType(type, index) {
      this.filters.cameraType = type; // 设置相机类型
      this.selectedCardIndex = index; // 设置选中的卡片索引

      // 根据相机类型更新产品列表标题
      switch (type) {
        case '标准相机':
          this.currentProductTitle = '标准相机产品列表';
          break;
        case '功能相机':
          this.currentProductTitle = '功能相机产品列表';
          break;
        case 'AI相机':
          this.currentProductTitle = 'AI相机产品列表';
          break;
        case '更多产品':
          this.currentProductTitle = '更多产品列表';
          break;
        default:
          this.currentProductTitle = '所有产品列表';
      }
    },
  },
};

</script>

<style scoped>
:deep(.el-table .even-row) {
  background-color: #dae7fa;
}

:deep(.el-table .odd-row) {
  background-color: #ffffff;
}

.el-table th, .el-table td {
  text-align: center;
}
table, th, td {
  border: 1px solid transparent;
  text-align: center;
  padding: 5px;
  color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
}
td {
  width: 33.33%; /* Distributes the table cells evenly */
}
</style>
