<template>
  <div class="user-container">
    <h1 class="user-title"> Hi, {{ username }}</h1>
    <el-row :gutter="20">
      <!-- 拥有产品部分 -->
      <el-col :xs="24" :sm="24" :md="12">
        <h2 class="user-title">拥有产品</h2>
        <div v-if="loading" class="product-cards">
          <el-scrollbar height="450px" class="custom-scrollbar">
            <el-row :gutter="20">
              <el-col v-for="n in 4" :key="n" :span="12" class="product-col">
                <el-card>
                  <div slot="header">
                    <el-skeleton :rows="1" animated />
                  </div>
                  <div>
                    <el-skeleton :rows="3" animated />
                  </div>
                  <div>
                    <el-skeleton :rows="1" animated />
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-scrollbar>
        </div>

        <div v-else class="product-cards">
          <el-scrollbar height="450px" class="custom-scrollbar">
            <el-row :gutter="20">
              <el-col v-for="product in products" :key="product.product_id" :span="12" class="product-col">
                <el-card>
                  <div slot="header">
                    <span>产品名称：{{ product.product_name }}</span>
                  </div>
                  <div>
                    <p>ID: {{ product.product_id }}</p>
                    <p>版本: {{ product.version_id }}</p>
                    <p>保修期至: {{ product.warranty_expiration }}</p>
                  </div>
                  <div>
                    <el-button type="info" @click="openTransferDialog(product.product_id)">
                      转让
                    </el-button>
                    <el-button type="info"
                      @click="UpdateFirmware(product.product_name, product.product_id, product.version_id)">
                      固件更新
                    </el-button>
                    <el-button type="info" @click="Download_P_Deatil(product.product_name)">
                      产品详细资料
                    </el-button>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-scrollbar>
        </div>
      </el-col>

      <!-- 添加产品部分 -->
      <el-col :xs="24" :sm="24" :md="12">
        <h2 class="user-title">添加产品</h2>
        <el-form :model="productForm" class="product-form" label-width="100px">
          <el-form-item label="MAC地址">
            <el-input v-model="productForm.macAddress" placeholder="请输入MAC地址"></el-input>
          </el-form-item>
          <el-form-item label="隐藏码">
            <el-input v-model="productForm.hiddenCode" placeholder="请输入隐藏码"></el-input>
          </el-form-item>
          <el-form-item label="序列号">
            <el-input v-model="productForm.serialNumber" placeholder="请输入序列号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addProduct">添加产品</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- Transfer Dialog -->
    <el-dialog title="转让产品" v-model="transferDialogVisible" width="30%">
      <el-form :model="transferForm">
        <el-form-item label="接受用户">
          <el-input v-model="transferForm.new_owner"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transferDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="transferProduct">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//先看明白login.vue里的axios.post方法 搞懂异步函数和同步函数 了解前后端具体的url以及怎么检查返回的信息后再往下看
import axios from 'axios';
import { el } from 'element-plus/es/locale/index.mjs';
// import config from '@/config.js'; // 使用绝对路径
import { mapActions } from 'vuex';
import total from './total.js';

export default {
  data() { // 定义 data 函数 包含了组件的数据
    return {
      loading: true, // 控制 Skeleton 的显示
      username: localStorage.getItem('username'), // 从 localStorage 获取用户名，赋值给前端变量
      userInfo: {
        username: '',
        email: ''
      },
      products: [],
      productForm: {
        serialNumber: '',
        macAddress: '',
        hiddenCode: ''
      },
      transferDialogVisible: false, // 控制转让对话框的显示
      transferForm: {
        new_owner: ''
      },
      activeIndex: '1', // 初始化 activeIndex
    };
  },
  created() {
    console.log('组件创建完成');
    this.loadUserInfo();
    this.fetchProducts();
    this.$forceUpdate;
  },

  methods: {
    loadUserInfo() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.userInfo = user;
      }
    },


    async fetchProducts() {
      const token = localStorage.getItem('access_token'); // 从 localStorage 获取令牌
      if (!token) {
        this.$message.error('未找到令牌，请重新登录');
        this.$router.push('/login');  // 重定向到登录页面
        return;
      }

      try {                               // 向后端发送请求在后端的urls.py定义了user_product的url
        // 检查对应的URL会发现需要身份验证，所以需要在请求头中添加令牌
        const response = await axios.get(`${total.BASEURL}/api/user_product/`, {
          headers: {
            'Authorization': `Bearer ${token}` // 身份验证 ----- 设置请求头-选择保存在本地的JWT令牌用bearer格式  身份验证
          }
        });
        if (response.status === 200) { //登陆成功的话应该检查返回的状态码
          //后端会返回符合该用户的所有产品列表以及用户名 根据上面的办法去检查对应的函数

          this.products = response.data.products;
          this.loading = false; // 关闭 Skeleton
        } else {
          this.$message.error('获取产品列表失败');
        }
      } catch (error) {
        this.$message.error('该用户没有产品');
        console.error('请求错误:', error);  // 打印错误信息
      }
    },

    async addProduct() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.$message.error('未找到令牌，请重新登录');
        this.$router.push('/login');  // 重定向到登录页面
        return;
      }

      try {
        const response = await axios.post(`${total.BASEURL}/api/user_product/`, this.productForm, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          this.$message.success('产品添加成功'); //信息提示框 - success类
          this.fetchProducts();
          this.productForm = { serialNumber: '', macAddress: '', hiddenCode: '' };
        } else {
          this.$message.error(response.data.message || '添加产品失败');
        }
      } catch (error) {
        if (error.response) {
          this.$message.error(error.response.data.message || '请求失败，请稍后再试');
        } else {
          this.$message.error('请求失败，请稍后再试');
          console.error('请求错误:', error);  // 打印错误信息
        }
      }
    },

    async transferProduct() {   // 转让产品 的异步函数
      const token = localStorage.getItem('access_token');
      const currentUsername = localStorage.getItem('username'); // 获取当前用户名
      if (!token) {
        this.$message.error('未找到令牌，请重新登录');
        this.$router.push('/login');  // 重定向
        return;
      }
      if (this.transferForm.new_owner === currentUsername) { // 判断是否转让给自己
        this.$message.error('不能将产品转让给当前用户');
        return;
      }
      try {
        const response = await axios.post(`${total.BASEURL}/api/tran_new_user/`, this.transferForm, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          this.$message.success({
            message: '产品添加成功',
            type: 'success'
          });
          this.transferDialogVisible = false;
          this.fetchProducts();
          this.transferForm = { new_owner: '' }; // 重置表单
        } else {
          this.$message.error(response.data.message || '产品转让失败');
        }
      } catch (error) {
        this.$message.error('请求失败，请稍后再试');
        console.error('请求错误:', error);  // 打印错误信息
      }
    },

    openTransferDialog(product_id) {
      this.transferDialogVisible = true;
      this.transferForm.product_id = product_id;
    },
    //好像没用到 - 暂时注视观察是否影响功能

    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath); // 定义 handleSelect 方法
    // }

    async UpdateFirmware(product_name, product_id, version_id) {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.$message.error('未找到令牌，请重新登录');
        this.$router.push('/login');  // 重定向到登录页面
        return;
      }
      try {
        const response = await axios.post(`${total.BASEURL}/api/download_firmware/`, {
          product_name: product_name,
          product_id: product_id,
          version_id: version_id
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'  // 确保可以处理文件响应
        });

        if (response.status === 200) {
          this.$message.success(response.data.message || '固件版本无需更新');
          this.fetchProducts();  // 重新获取产品数据
        } else if (response.status === 201) {
          // 处理文件下载响应
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'firmware.bin';  // 默认文件名

          if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
          }


          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;  // 使用从后端获取的文件名
          document.body.appendChild(a);
          a.click();
          a.remove();
          // 弹出确认对话框
          this.$confirm('固件已下载，您是否确认更新成功？', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(async () => {
            // 用户点击确认后发送请求到后端
            try {
              const confirmationResponse = await axios.post(`${total.BASEURL}/api/confirm_update/`, {
                product_name: product_name,
                product_id: product_id
              }, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
              });
              this.$message.success('更新已确认成功');
              this.fetchProducts();  // 重新获取产品数据
              console.log('Confirmation response:', confirmationResponse.data);
            } catch (confirmationError) {
              this.$message.error('确认更新失败，请稍后再试');
              console.error('确认更新请求错误:', confirmationError);
            }
          }).catch(() => {
            this.$message.info('已取消确认');
          });

          this.fetchProducts();  // 重新获取产品数据
        } else {
          this.$message.error(response.data.message || '固件更新失败');
        }
      } catch (error) {
        this.$message.error('请求失败，请稍后再试');
        console.error('请求错误:', error);  // 打印错误信息
      }
    },

    async Download_P_Deatil(product_name) {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.$message.error('未找到令牌，请重新登录');
        this.$router.push('/login');  // 重定向到登录页面
        return;
      }
      try {
        // 发送POST请求到后端
        const response = await axios.post(`${total.BASEURL}/api/download_detail_user/`, {
          product_name: product_name
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'  // 确保可以处理文件响应
        });

        // 检查响应状态码
        if (response.status === 201) {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'product_detail.pdf';  // 默认文件名

          if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
            fileName = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
          }

          // 创建Blob对象并触发下载
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();

          this.$message.success('产品详细说明文件已下载');
        } else {
          this.$message.error('无法下载产品详细说明文件');
        }
      } catch (error) {
        this.$message.error('请求失败，请稍后再试');
        console.error('请求错误:', error);  // 打印错误信息
      }
    }
  }

};
</script>

<style scoped>
.user-title {
  color: rgba(0, 0, 0, 0.7);
  /* 蓝色字体，70%不透明 */
}

.user-container {
  padding: 20px;
  position: relative;
  z-index: 0;
  min-height: 100vh;
  /* 确保页面内容至少占满屏幕 */
}

.user-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../media/bluebg2.jpg');
  /* 背景图片路径 */
  background-size: cover;
  /* 图片覆盖整个容器 */
  background-position: center;
  /* 图片居中显示 */
  background-repeat: no-repeat;
  /* 防止图片重复 */
  opacity: 0.2;
  /* 设置透明度 */
  z-index: -1;
  /* 伪元素置于背景 */
  pointer-events: none;
  /* 确保点击穿透到下面的元素 */
}

.el-header {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.el-main {
  padding: 20px;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.el-card {
  margin-bottom: 20px;
  min-width: 200px;
  background-color: rgba(255, 255, 255, 0.6);
  /* 设置背景色的透明度 */
}

.no-products {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #e96c6c;
}

.custom-scrollbar .el-scrollbar__bar {
  width: 12px;
  /* 增加滚动条宽度 */
}

.custom-scrollbar .el-scrollbar__thumb {
  background-color: #409eff;
  /* 滚动条颜色 */
}

.product-col {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 20px;
}

.product-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
  /* 设置背景色的透明度 */
}

.product-form .el-form-item {
  margin-bottom: 15px;
}

.dialog-footer {
  text-align: right;
}

/* 响应式布局 */
@media (max-width: 768px) {
  .el-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
