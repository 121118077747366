<template>
  <div id="app">
    <!-- 顶部导航栏 -->
    <el-container :key="$route.fullPath">
      <el-header :class="{ scrolled: isScrolled }">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
          style="height: 80px;"
        >

          <!-- 公司logo -->
          <div class="logo-container">
            <el-menu-item index="0">
              <router-link to="/Home">
                <img
                  class="logo"
                  :src="require('@/media/logo.pic.jpg')"
                  alt="公司logo"
                />
              </router-link>
            </el-menu-item>
          </div>

          <!-- 导航栏及二级菜单 -->
          <el-menu-item index="1">
            <router-link to="/Home">首页</router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/Product">产品</router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/TechSupport">支持</router-link>
          </el-menu-item>
          <el-menu-item index="4">
            <router-link to="/Download">下载</router-link>
          </el-menu-item>
          <el-menu-item index="5">
            <router-link to="/Subscribe">订阅服务</router-link>
          </el-menu-item>

          <!-- 导航栏右半部分开始 -->
          <div class="flex-grow" />
          <!-- 用户界面按钮和登出按钮 -->
          <el-menu-item index="5" v-if="isAuthenticated">
            <el-avatar
              :size="40"
              :src="require('@/media/userlogo.jpg')"
              @click="goToUserPage"
              style="cursor: pointer;">
            </el-avatar>
          </el-menu-item>
          <!-- 登出按钮 -->
          <el-menu-item v-if="isAuthenticated" index="6">
            <el-button @click="handleLogout">登出</el-button>
          </el-menu-item>
          <!-- 登录按钮 -->
          <el-menu-item index="7" v-else>
            <router-link to="/Login">登录</router-link>
          </el-menu-item>
          <!-- 导航栏右半部分结束 -->

        </el-menu>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- 底部slogan -->
      <h2 class="slogan">
        面相全球的工业视像识别相机、传输及算法模块；
        秉承极致、快、服务的理念；
        良工必有博峤
      </h2>
       <!-- 底部导航栏 -->
      <el-footer class="footer-nav">
      <div class="container">
        <el-row>
          <el-col :span="6" class="footer-col">
            <img src="@/media/telephone.jpg" alt="电话图标" class="resized-image">
            <p>总机电话 15220073877</p>
          </el-col>
          <el-col :span="6" class="footer-col">
            <img src="@/media/mail.jpg" alt="邮箱图标" class="resized-image">
            <p>联系邮箱 yongcong_yuan@zhbojay.com</p>
          </el-col>
          <el-col :span="6" class="footer-col">
            <img src="@/media/maintenance.jpg" alt="售后图标" class="resized-image">
            <p>客服热线 17882351005</p>
          </el-col>
          <el-col :span="6" class="footer-col">
            <img src="@/media/automation.jpg" alt="技术图标" class="resized-image">
            <p>技术邮箱 xiaohe@zhbojay.com</p>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <div class="footer_logo">
              <img style="width: 80px" :src="require('@/media/logoWhite.png')" alt="公司logo" />
            </div>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <ul>
              <li><router-link to="/Home">首页</router-link></li>
              <li><router-link to="/Login">登录</router-link></li>
              <li><router-link to="/Login">注册</router-link></li>
            </ul>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <ul>
              <li><router-link to="/ProductAi">AI相机</router-link></li>
              <li><router-link to="/ProductEdgeCalculate">边缘计算相机</router-link></li>
              <li><router-link to="/ProductStandard">标准相机</router-link></li>
              <li><a href="/MoreProduct">更多产品</a></li>
            </ul>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
          <h4>扫码关注我们的动态</h4>
          <el-row class="contact-icons" >
            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click">
                <template #reference>
                  <img src="@/media/wx.png" class="icon-image" alt="Icon 1">
                </template>
                <img src="@/media/automation.jpg" alt="QR Code 1" style="width: 120px;">
              </el-popover>
            </el-col>

            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click"> 
                <template #reference>
                  <img src="@/media/dy.png" class="icon-image" alt="Icon 2">
                </template>
                <img src="@/media/automation.jpg" alt="QR Code 2" style="width: 120px;">
              </el-popover>
            </el-col>

            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click">
                <template #reference>
                  <img src="@/media/wxsph.png" class="icon-image" alt="Icon 3">
                </template>
                <img src="@/media/automation.jpg" alt="QR Code 3" style="width: 120px;">
              </el-popover>
            </el-col>

            <!-- 根据需要添加更多图标 -->
          </el-row>

          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="footer-col">
            <p class="copyright-text">
              Copyright © 2024 All Rights Reserved 深圳市博峤技术有限公司·版权所有 粤ICP备2024297890号
            </p>
          </el-col>
        </el-row>
      </div>
    </el-footer>
    <!-- 底部导航栏 -->
    </el-container>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
import './views/total.css';

import {
  ElContainer,
  ElHeader,
  ElMain,
  ElFooter,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElRow,
  ElCol,
  ElAvatar
} from 'element-plus';

export default {
  name: 'Navbar', // 将 'name' 移到了顶部
  components: {
    ElContainer,
    ElHeader,
    ElMain,
    ElFooter,
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    ElRow,
    ElCol,
    ElAvatar
  },
  setup() {
    const activeIndex = ref('1');
    const isScrolled = ref(false); // 新增，用于跟踪滚动状态

    const handleSelect = (key, keyPath) => {
      console.log(`selected ${key} from ${keyPath}`);
    };

    const router = useRouter();
    const goToUserPage = () => {
      console.log(router);
      router.push('/User');
    };

    // 滚动事件处理函数
    const handleScroll = () => {
      isScrolled.value = window.scrollY > 10; // 当滚动超过10px时，认为页面已滚动
    };

    // 添加路由监听，根据当前路由更新activeIndex
    const updateActiveIndex = (route) => {
      const path = route.path;
      // 根据路径设置对应的activeIndex
      switch (true) {
        case path === '/Home':
          activeIndex.value = '1';
          break;
        case path.includes('/Product'):
          activeIndex.value = '2';
          break;
        case path.includes('/About'):
          activeIndex.value = '3';
          break;
        case path.includes('/Subscribe'):
          activeIndex.value = '4';
          break;
        case path.includes('/Download'):
          activeIndex.value = '5';
          break;
        case path === '/User':
          activeIndex.value = '6';
          break;
        default:
          activeIndex.value = '1';
      }
    };

    // 监听路由变化
    onMounted(() => {
      updateActiveIndex(router.currentRoute.value); // 初始化
      router.afterEach((to) => {
        updateActiveIndex(to);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      activeIndex,
      handleSelect,
      goToUserPage,
      isScrolled // 返回到模板中，以便绑定类
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['checkAuthentication', 'logout']),
    handleLogout() {
      this.logout();
      this.$router.push('/login'); // 跳转到登录页
    }
  },
  created() {
    this.checkAuthentication(); // 检查用户是否已登录
  }
};
</script>


<style scoped>
/* 顶部导航栏基础样式 */
.el-header {
  display: flex;
  position: fixed;
  z-index: 1000;
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

/* 主菜单样式 */
.el-menu-demo {
  border: none !important;
  background-color: transparent;
}

.el-menu-item, .el-sub-menu__title {
  height: 80px;
  line-height: 80px;
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  transition: all 0.3s;
}

/* 二级菜单样式 */
.el-sub-menu .el-menu {
  background-color: rgba(27, 132, 244, 0.98) !important;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.el-sub-menu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  min-width: 160px;
}

.el-menu-item:hover, .el-sub-menu__title:hover {
  background-color: rgba(64, 158, 255, 0.1) !important;
  color: #409EFF !important;
}

/* 滚动效果 */
.el-header.scrolled {
  height: 60px;
  background-color: rgba(82, 170, 241, 0.98);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.scrolled .el-menu-item,
.scrolled .el-sub-menu__title {
  height: 60px;
  line-height: 60px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .el-header {
    height: auto;
    padding: 0;
  }

  .el-menu-demo {
    flex-direction: column;
    width: 100%;
  }

  .el-menu-item, .el-sub-menu__title {
    height: 50px;
    line-height: 50px;
    justify-content: center;
  }

  .logo-container {
    padding: 10px 0;
  }

  .logo {
    width: 40px;
  }

  .el-sub-menu .el-menu {
    position: static;
    box-shadow: none;
  }

  .el-sub-menu .el-menu-item {
    padding-left: 40px !important;
  }

  /* 移动端菜单展开按钮 */
  .menu-toggle {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

/* 链接样式优化 */
.el-menu-item a,
.el-sub-menu__title a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
}

/* 激活状态样式 */
.el-menu-item.is-active,
.el-menu-item.is-active a {
  color: #409EFF !important;
  font-weight: 600;
}

/* Logo容器样式更新 */
.logo-container {
  display: flex;
  align-items: center;
  transition: all 0.3s ease; /* 添加过渡效果 */
}

.logo {
  width: 60px;
  height: auto;
  transition: all 0.3s ease; /* Logo大小变化的过渡效果 */
}

/* 滚动时Logo大小调整 */
.scrolled .logo {
  width: 50px; /* 滚动时缩小Logo */
}

/* 为 <el-main> 添加上部填充，以避免被固定的导航栏覆盖 */
.el-main {
  padding-top: 80px; /* 导航栏的高度 */
}

/* 滚动时修改背景透明度 */
.el-header.scrolled {
  background-color: rgba(255, 255, 255, 0.95); /* 滚动时稍微降低透明度 */
}

.el-menu-demo {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 16px;
  border-bottom: none; /* 确保没有底部边框 */
}

/* Logo的调整 */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px; /* 默认Logo大小 */
  height: auto;
}

/* 弹性填充空间 */
.flex-grow {
  flex-grow: 1;
}

/* 头像和登出按钮的调整 */
.el-avatar,
.el-button {
  margin-left: 10px;
}

/* 响应式设计的媒体查询 */
@media (max-width: 768px) {
  .el-header {
    flex-direction: column;
    height: auto;
    position: relative; /* 小屏幕上取消固定定位 */
  }

  .el-menu-demo {
    flex-direction: column;
    align-items: center; /* 居中对齐菜单项 */
    border-bottom: none; /* 确保在小屏幕时没有底部边框 */
    width: 100%;
  }

  .logo {
    width: 40px; /* 移动设备上较小的Logo大小 */
  }

  .el-menu-item,
  .el-sub-menu {
    width: 100%;
    padding: 10px 20px;
  }

  .el-avatar {
    width: 30px;
    height: 30px;
  }

  .el-button {
    padding: 5px 10px; /* 较小的按钮尺寸 */
  }
}

/* 底部导航栏 */
.footer-nav {
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
  color: #fff;
  text-align: center;
  padding: 40px 0;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
}

.footer-col h2 {
  color: #fff;
  margin-bottom: 20px;
}

.footer-col ul {
  list-style: none;
  padding: 0;
}

.footer-col ul li {
  margin-bottom: 10px;
    text-align: center;
}

.footer-col ul li a {
  color: #fff;
  text-decoration: none;
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
}

.footer-col ul li a:hover {
  color: #409EFF;
}

.footer_logo img {
  max-width: 150px;
  margin-bottom: 20px;
}

.contact-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap; /* 允许元素换行 */
}

.icon-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
  background-color: #4397c8;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* 响应式布局调整 */
@media (max-width: 768px) {
  .footer-col {
    display: flex;
    text-align: center;
    padding: 10px;
    margin-bottom: 0px;
    padding: 5px;
  }

  .footer-logo {
    margin-bottom: 20px;
  }

  .footer_logo img {
    max-width: 100px;
  }

  /* 在手机上每列占满宽度并按顺序排列 */
  .footer-col {
    flex-basis: 100%;
    max-width: 100%;
    padding: 10px;
    margin-bottom: 0px;
  }

  .footer-nav .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* 标语部分 */
.slogan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* 可根据需要调整 */
  background-color: #f0f2f5; /* 背景色，可选 */
  padding: 20px;
  text-align: center;
}

.slogan {
  font-size: 30px; /* 字体大小，可根据需要调整 */
  font-weight: bold; /* 字体粗细 */
  color: #333; /* 文字颜色 */
  line-height: 1.5; /* 行高 */
  background: linear-gradient(to right, #1970D2, #9CD7FF); /* 渐变背景色 */
  -webkit-background-clip: text;
  color: transparent;
  animation: fadeIn 3s ease-in-out;
  text-align: center; /* 文字水平居中 */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 照片大小 */
.resized-image {
  width: 11%; /* 调整为所需的宽度，比如 100%，50px，或者 300px */
  height: auto; /* 确保图片保持宽高比 */
}

/* 菜单项的CSS样式 */
.el-menu-item a {
  color: inherit;
  text-decoration: none;
}

.el-menu-item.is-active {
  color: inherit;
}

.el-menu-item a:visited {
  color: inherit;
}

.el-menu-item a:hover {
  color: #ffffff; /* Element Plus 主色 */
}

.el-menu-item a:active {
  color: inherit;
}

/* 二级菜单的CSS样式 */
.el-sub-menu a {
  color: inherit;
  text-decoration: none;
}

.el-sub-menu.is-active {
  color: inherit;
}

.el-sub-menu a:visited {
  color: inherit;
}

.el-sub-menu a:hover {
  color: #409EFF; /* 光标悬停时的颜色 */
}

.el-sub-menu a:active {
  color: inherit;
}

/* 主菜单容器样式 */
.el-menu-demo {
  border: none !important;
  background-color: transparent;
  position: relative; /* 添加相对定位 */
}

/* 二级菜单容器样式 */
.el-sub-menu .el-menu {
  position: absolute !important; /* 确保绝对定位 */
  top: 80px !important; /* 与主菜单高度对齐 */
  background-color: rgba(255, 255, 255, 0.98) !important;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  min-width: 160px;
  z-index: 2000; /* 确保在最上层 */
}

/* 滚动时二级菜单位置调整 */
.scrolled .el-sub-menu .el-menu {
  top: 60px !important; /* 适应滚动后的主菜单高度 */
}

/* 二菜单项样式 */
.el-sub-menu .el-menu-item {
  height: 50px !important;
  line-height: 50px !important;
  padding: 0 20px !important;
  margin: 0;
  font-size: 16px !important;
  color: #2c3e50;
}

/* 二级菜单悬停效果 */
.el-sub-menu .el-menu-item:hover {
  background-color: rgba(64, 158, 255, 0.1) !important;
  color: #409EFF !important;
}

/* 确保子菜单不会被其他元素遮挡 */
.el-sub-menu__title {
  position: relative;
  z-index: 1999;
}

/* 修复子菜单链接样式 */
.el-sub-menu .el-menu-item a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 50px !important;
  color: inherit;
  text-decoration: none;
}
</style>
