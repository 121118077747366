// 此界面主要负责不同界面的js功能设置。
// 注意:此网页同时包含提交表单的任务等 如果后续需要拆分与后端连接的功能到另一个js里面
import { ElContainer, ElHeader, ElMain, ElRow, ElCol, ElCard, ElButton, ElMessage } from 'element-plus';
// src/config.js
const BASEURL = 'http://127.0.0.1:8000';


export default {
  BASEURL,
  name: 'Home',
  components: {
    ElContainer,
    ElHeader,
    ElMain,
    ElRow,
    ElCol,
    ElCard,
    ElButton,
    ElMessage
    },
    
    methods: {
    subscribe(plan) {
      ElMessage({
        message: `您已选择订阅${plan}`,
        type: 'success',
      });
    },

    submitForm() {
        console.log('Form submitted with:', this.form);
        // Here you can add your form submission logic, e.g., sending data to a server
    }
    }
}