import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './views/total.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store);
app.use(ElementPlus, { locale: zhCn });



app.config.errorHandler = (err, vm, info) => {
    console.error('Global error handler:', err, info);
  };

app.use(router);
app.mount('#app');
