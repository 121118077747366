<!-- src/views/Home.vue -->
<template>
  <div class="home-container">
    <el-container>
      <el-main>

          <!-- 走马灯模块 -->
          <div class="carousel-section">
            <div class="carousel-container">
              <el-carousel :interval="5000" arrow="always" @change="handleCarouselChange" height="400px">
                <el-carousel-item v-for="(image, index) in carouselImages" :key="index">
                  <img 
                    :src="image" 
                    alt="Carousel Image" 
                    class="carousel-image"
                    @load="(e) => handleImageLoad(e, index)"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>


        <div class="background-overlay">
          <!-- 文本显示模块 -->
          <el-row type="flex" justify="center" :gutter="20" class="text-section">
            <el-col :span="12">
              <h3 class="parts-title" style="--after-width: 140px;">产品系列</h3>
            </el-col>
          </el-row>

          <el-row type="flex" justify="center" class="text-description-section">
            <el-col :span="16">
              <p class="text-description">精准捕捉每一个细节，为您的生产线注入智能之眼;融合先进算法，打造极致图像质量，赋能智能制造新视界</p>
            </el-col>
          </el-row>

          <!-- 卡片模块 -->
          <el-row>
            <el-col :span="24">
              <el-row :gutter="10" class="card-section">
                <el-col 
                  v-for="(o, index) in cardImages" 
                  :key="index" 
                  :xs="24" 
                  :sm="24" 
                  :md="8" 
                  :lg="8"
                  class="card-col"
                >
                  <el-card :body-style="{ padding: '0px' }" class="product-card">
                    <img 
                      :src="o.currentSrc" 
                      class="image" 
                      @click="goToProPage" 
                      @mouseenter="handleMouseEnter(o)"
                      @mouseleave="handleMouseLeave(o)" 
                      :class="{ 'image-hover': o.isHovered }" 
                    />
                    <div class="card-content">
                      <div class="card-name">{{ o.name }}</div>
                      <div class="card-description">{{o.p }}</div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div><!-- background-overlay背景到这里结束 -->
        
        <!-- 优秀案例模块 -->
        <el-row type="flex" justify="center" :gutter="20" class="text-section">
          <el-col :span="12">
            <h3 class="parts-title" style="--after-width: 140px;">优秀案例</h3>
          </el-col>
        </el-row>

        <!-- 新增的容器 -->
        <div class="sample-background">
          <div class="sample-container">
            <div class="sample-grid">
              <div 
                class="sample-item" 
                v-for="(item, index) in SampleList" 
                :key="index"
                @click="gosample(item.id)"
              >
                <div class="sample-image-wrapper">
                  <img :src="item.image" :alt="item.alt" />
                  <div class="sample-overlay">
                    <h4>{{ item.title }}</h4>
                    <p class="sample-description">{{ item.description }}</p>
                    <span class="sample-date">{{ item.date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新闻模块 -->

        <el-row type="flex" justify="center" :gutter="20" class="text-section">
          <el-col :span="12">
            <h2 class="parts-title" style="--after-width: 140px;">公司新闻</h2>
          </el-col>
        </el-row>

        <div class="newscontainer"> <!-- 主容器 -->
          <div class="news-project">
          </div>

          <div class="news-images">
            <div 
              class="news-item" 
              @click="navigateToDetail(item.id)" 
              v-for="(item, index) in newsList" 
              :key="index"
            >
              <img :src="item.image" :alt="item.alt" />
              <p>{{ item.title }}</p>
              <span class="date-stamp">{{ item.date }} >></span>
            </div>

          </div> 
        </div>

        <!-- 您需要获得什么支持，联系我们 -->
        <div class="support">
          <p>您需要获得什么支持？</p>
          <p>我很乐意为你提供产品选择建议，并助您为应用找到合适的解决方案。</p>
          <router-link to="/login">
            <el-button class="custom-button" type="primary" round>联系我们</el-button>
          </router-link>
        </div>




      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ElContainer } from 'element-plus';
import './Home.css'; 
export default {
  data() {
    return {
       
    newsList: [  //静态文件储存在assert文件夹中
      { id: 1, image: require('@/assets/media/newimage1.jpg'),alt: '项目1', title: '深圳市博峤技术有限公司成立于2022年09月05日', date: '2022.9.5' },
      { id: 2, image: require('@/assets/media/newimage2.jpg'),alt: '项目2', title: '新征程 新起点---博自研面阵相机上市', date: '2024.5.20' },
      { id: 3, image: require('@/assets/media/newimage3.jpg'),alt: '项目3', title: '心聚力 赢未来---博杰2023年度总结及评优表彰大会', date: '2024.1.15' },
      { id: 4, image: require('@/assets/media/newimage4.jpg'),alt: '项目4', title: '奇趣家庭日 爱在博杰家——家庭开放日', date: '2024.1.21' },
    ],
    SampleList: [
      { 
        id: 1, 
        image: require('@/assets/media/newimage1.jpg'),
        alt: '案例1', 
        title: '冲压模具检测', 
        description: '边缘检测算法与工业相机的完美结合',
        date: '2023.5.17' 
      },
      { 
        id: 2, 
        image: require('@/assets/media/newimage2.jpg'),
        alt: '案例2', 
        title: '振动盘CCD分选系统', 
        description: '4摄像头高速识别与判定，达4000fps',
        date: '2024.4.20' 
      },
      { 
        id: 3, 
        image: require('@/assets/media/newimage3.jpg'),
        alt: '案例3', 
        title: '器件有无检测', 
        description: '40万像素功能相机+有无算法适配16000个/min的检测速度',
        date: '2024.11.16' 
      },
      { 
        id: 4, 
        image: require('@/assets/media/newimage4.jpg'),
        alt: '案例4', 
        title: '标准工业相机', 
        description: '精准捕捉，多分辨率与丰富接口适配不同使用场景',
        date: '2024.1.9' 
      }
    ],

      currentDate: new Date().toLocaleString(),
      // 走马灯图片
      carouselImages: [
        require('@/media/cameraBasic.jpg'),
        require('@/media/cameraLiner.jpg'),
        require('@/media/cameraMini.jpg'),
        require('@/media/cameraTotal.jpg')
      ],
      // 图片卡片的图片数组
      cardImages: [
        {
          // 默认图片 （后续增加新的照片）
          defaultSrc: require('@/media/BasicForCard.jpg'),
          // 鼠标悬浮时的图片
          hoverSrc: require('@/media/BasicForCard.jpg'),
          // 当前显示的图片
          currentSrc: require('@/media/BasicForCard.jpg'),
          isHovered: false,
          name: '基础相机系列', // 卡片名称在这里更改
          p:'多分辨率，接口丰富，结构经典'
        },
        {
          defaultSrc: require('@/media/LinerForCard.jpg'),
          hoverSrc: require('@/media/LinerForCard.jpg'),
          currentSrc: require('@/media/LinerForCard.jpg'),
          isHovered: false,
          name: '功能相机系列',
          p:'算法与相机的完美融合'
        },
        {
          defaultSrc: require('@/media/MiniForCard.jpg'),
          hoverSrc: require('@/media/MiniForCard.jpg'),
          currentSrc: require('@/media/MiniForCard.jpg'),
          isHovered: false,
          name: 'AI相机系列',
          p: 'AI加持，相机超凡表现'
        }
      ]
      ,
    };
  },
  computed: {
    carouselStyle() {
      return {
        '--carousel-bg-left': this.currentBgColor.left,
        '--carousel-bg-right': this.currentBgColor.right
      }
    }
  },
  methods: {
    navigateToDetail(newsId) {
    this.$router.push(`/news_detail/${newsId}`);
    },
    gosample(sampleID) {
      this.$router.push(`/sample/${sampleID}`);
    },
    goToProPage() {
      this.$router.push('/Product');
    },
    handleMouseEnter(image) {
      image.currentSrc = image.hoverSrc;
      image.isHovered = true;
    },
    handleMouseLeave(image) {
      image.currentSrc = image.defaultSrc;
      image.isHovered = false;
    }
  }
}
</script>
