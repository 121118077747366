//使用vuex管理全局状态，包括用户是否登录、用户名等信息
import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('access_token'),
    username: localStorage.getItem('username') || ''
  },
  //设置登录状态和用户名
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setUsername(state, username) {
      state.username = username;
    }
  },
  //登录、登出、检查登录状态
  actions: {
    login({ commit }, { access, refresh, username }) {
      commit('setAuthentication', true);
      commit('setUsername', username);
      //将token存储到localStorage中
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('username', username);
      //设置axios的请求头，将token放在请求头中
      axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
    },
    logout({ commit }) {
      commit('setAuthentication', false);
      commit('setUsername', '');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('username');
      delete axios.defaults.headers.common['Authorization'];
    },
    checkAuthentication({ commit }) {
      const token = localStorage.getItem('access_token');
      commit('setAuthentication', !!token);
      commit('setUsername', localStorage.getItem('username') || '');
    }
  },
  //获取登录状态
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    username: state => state.username
  }
});