export const cameraDatabase = {
  // 面阵相机数据
  area: {
    // 标准相机
    standard: [
      {
        id: 'AG040S-GC',
        productModel: 'BoQ-AG040S-GC',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG040S-GM',
        productModel: 'BoQ-AG040S-GM',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG230S-GC',
        productModel: 'BoQ-AG230S-GC',
        sensorModel: 'IMX392',
        resolution: '1920*1200',
        maxFrameRate: '201fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG230S-GM',
        productModel: 'BoQ-AG230S-GM',
        sensorModel: 'IMX392',
        resolution: '1920*1200',
        maxFrameRate: '201fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG250G-GC',
        productModel: 'BoQ-AG250G-GC',
        sensorModel: 'GMAX4002',
        resolution: '2048*1200',
        maxFrameRate: '660fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG250G-GM',
        productModel: 'BoQ-AG250G-GM',
        sensorModel: 'GMAX4002',
        resolution: '2048*1200',
        maxFrameRate: '660fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG500S-GC',
        productModel: 'BoQ-AG500S-GC',
        sensorModel: 'IMX264',
        resolution: '2448*2048',
        maxFrameRate: '24.2fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG500S-GM',
        productModel: 'BoQ-AG500S-GM',
        sensorModel: 'IMX264',
        resolution: '2448*2048',
        maxFrameRate: '24.2fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG1600S-GM',
        productModel: 'BoQ-AG1600S-GM',
        sensorModel: 'IMX542',
        resolution: '5328*3040',
        maxFrameRate: '52fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG1600S-GC',
        productModel: 'BoQ-AG1600S-GC',
        sensorModel: 'IMX542',
        resolution: '5328*3040',
        maxFrameRate: '52fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG510S-GM',
        productModel: 'BoQ-AG510S-GM',
        sensorModel: 'IMX548',
        resolution: '2472*2064',
        maxFrameRate: '114fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '标准相机'
      },
      {
        id: 'AG510S-GC',
        productModel: 'BoQ-AG510S-GC',
        sensorModel: 'IMX548',
        resolution: '2472*2064',
        maxFrameRate: '114fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '标准相机'
      }
    ],
    // 功能相机
    function: [
      {
        id: 'EAG040S-GC',
        productModel: 'BoQ-EAG040S-GC',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '现已上线',
        type: '功能相机'
      },
      {
        id: 'EAG040S-GM',
        productModel: 'BoQ-EAG040S-GM',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '现已上线',
        type: '功能相机'
      },
      // ... 其他功能相机数据
    ],
    // AI相机
    ai: [
      {
        id: 'AAG040S-GC',
        productModel: 'BoQ-AAG040S-GC',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'AAG040S-GM',
        productModel: 'BoQ-AAG040S-GM',
        sensorModel: 'IMX287',
        resolution: '720*540',
        maxFrameRate: '312fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'AAG250G-GC',
        productModel: 'BoQ-AAG250G-GC',
        sensorModel: 'GMAX4002',
        resolution: '2048*1200',
        maxFrameRate: '660fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'AAG250G-GM',
        productModel: 'BoQ-AAG250G-GM',
        sensorModel: 'GMAX4002',
        resolution: '2048*1200',
        maxFrameRate: '660fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'AAG510S-GM',
        productModel: 'BoQ-AAG510S-GM',
        sensorModel: 'IMX548',
        resolution: '2472*2064',
        maxFrameRate: '114fps',
        dataInterface: 'GigE',
        colorType: '彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'AAG510S-GC',
        productModel: 'BoQ-AAG510S-GC',
        sensorModel: 'IMX548',
        resolution: '2472*2064',
        maxFrameRate: '114fps',
        dataInterface: 'GigE',
        colorType: '黑白',
        productionStatus: '计划上线',
        type: 'AI相机'
      }
    ]
  },
  // 线扫相机数据
  line: {
    // 标准相机
    standard: [
      {
        id: 'LS4kG04-12',
        productModel: 'BoQ-LS4kG04-12',
        sensorModel: 'GL7004',
        resolution: '4096*1/2/3/4',
        maxFrameRate: '200KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: '标准相机'
      },
      {
        id: 'LS8kG04-12',
        productModel: 'BoQ-LS8kG04-12',
        sensorModel: 'GL7008',
        resolution: '8192*1/2/3/4',
        maxFrameRate: '200K/100K/66K/50KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: '标准相机'
      },
      {
        id: 'LS16kG02-12',
        productModel: 'BoQ-LS16kG02-12',
        sensorModel: 'GL3516',
        resolution: '16384*1/2',
        maxFrameRate: '120K/60KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: '标准相机'
      },
    ],
    // AI相机
    ai: [
      {
        id: 'ALS4kG04-12',
        productModel: 'BoQ-ALS4kG04-12',
        sensorModel: 'GL7004',
        resolution: '4096*1/2/3/4',
        maxFrameRate: '200KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'ALS8kG04-12',
        productModel: 'BoQ-ALS8kG04-12',
        sensorModel: 'GL7008',
        resolution: '8192*1/2/3/4',
        maxFrameRate: '200K/100K/66K/50KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
      {
        id: 'ALS16kG02-12',
        productModel: 'BoQ-ALS16kG02-12',
        sensorModel: 'GL3516',
        resolution: '16384*1/2',
        maxFrameRate: '120K/60KHz',
        dataInterface: 'CameraLink/LAN',
        colorType: '黑白/彩色',
        productionStatus: '计划上线',
        type: 'AI相机'
      },
    ]
  }
}
