<!-- src/views/productList/MoreProduct.vue -->
<template>
  <div>
    <el-container>
      <el-header>
        <h2 class="page-title">配件</h2>
      </el-header>

      <el-main>
        <div class="tabs-container">
          <el-tabs class="module-tabs" v-model="activeTab">
            <el-tab-pane class="tab-pane" label="电源适配器" name="power"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="相机供电及IO线" name="io"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="数据线缆" name="data"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="采集卡" name="card"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="工具" name="tools"></el-tab-pane>
          </el-tabs>
        </div>

        <!-- 不同内容区域 -->
        <div v-if="activeTab === 'data'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in dataParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'card'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in cardParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'power'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in powerParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'tools'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in toolsParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'io'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in ioParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <el-button class="more-button" style="border: none; color: #7aa7f7;">更多 →</el-button>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'data',
      dataParts: [
        { name: 'MV-ACG-RJ45s-RJ45-HF-30m', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-ACG-RJ45s-RJ45-ST-10m', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-ACG-RJ45s-RJ45-SF-7m', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-ACG-RJ45s(up)-RJ45-HF-5m', image: require('@/media/logo.pic.jpg') },
      ],
      cardParts: [
        { name: 'MV-GE2002', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-GU2104', image: require('@/media/logo.pic.jpg') },
      ],
      powerParts: [
        { name: 'MV-GE2002', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-GU2104', image: require('@/media/logo.pic.jpg') },
      ],
      ioParts: [
        { name: 'ADS-26FSG-12 12024EPCN', image: require('@/media/logo.pic.jpg') },
        { name: 'ADS-26FSG-12 12024EPCN', image: require('@/media/logo.pic.jpg') },
      ],
      toolsParts: [
        { name: 'MV-GE2002', image: require('@/media/logo.pic.jpg') },
        { name: 'MV-GU2104', image: require('@/media/logo.pic.jpg') },
      ],
    }
  },

}
</script>

<style scoped>
/* 页面标题样式 */
.page-title {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

/* 伪元素 平行四边形 */
.page-title::after {
  content: '';
  display: block;
  width: var(--after-width);
  height: 5px;
  background-color: #409eff;
  position: absolute;
  left: 0;
  transform: skewX(-20deg);
  bottom: -1px;
}

/* 配件 */
.parts-container {
  margin: 20px 0;
}

.part-image {
  max-width: calc(50% - 10%);
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.card-content {
  text-align: center;
  margin-top: 10px;
}

.more-button {
  margin-top: 20px;
  display: block;
  text-align: center;
  width: 100%;
  color: #7aa7f7;
}

/* 卡片默认样式 */
.el-card {
  transition: border-color 0.3s ease, box-shadow 0.3s ease, border-width 0.3s ease;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #409eff;
  margin-bottom: 10px;
}

/* 悬停时的卡片样式 */
.el-card:hover {
  border-color: rgba(64, 158, 255, 0.5);
  border-width: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 配件选择器样式 */
.module-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 18px;
}

/* 配件选择模块容器样式 */
.tabs-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

/* 深度选择器>>>覆盖样式 */
.module-tabs>>>.el-tabs__item {
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
}

/* 悬停状态样式 */
.module-tabs>>>.el-tabs__item:hover {
  color: #409eff;
}

</style>
