<template>
  <div class="download-container">
    <el-container class="responsive-container">
      <!-- 左侧相机列表 -->
      <el-aside class="responsive-aside">
        <el-card class="camera-list">
          <template #header>
            <div class="card-header">
              <span>产品列表</span>
              <div class="radio-group-wrapper">
                <el-radio-group v-model="currentCameraType" size="small">
                  <el-radio-button label="area">面阵相机</el-radio-button>
                  <el-radio-button label="line">线扫相机</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </template>
          
          <!-- 相机类型分组 -->
          <el-menu 
            :default-active="activeCamera"
            @select="handleCameraSelect"
          >
            <el-sub-menu v-for="(cameras, type) in currentCameras" :key="type" :index="type">
              <template #title>
                <span>{{ getCameraTypeName(type) }}</span>
              </template>
              <el-menu-item 
                v-for="camera in cameras" 
                :key="camera.id"
                :index="camera.id"
              >
                {{ camera.productModel }}
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-card>
      </el-aside>

      <!-- 右侧下载区域 -->
      <el-main class="responsive-main">
        <el-card v-if="selectedCamera" class="download-card">
          <template #header>
            <div class="card-header">
              <h2>{{ selectedCamera.productModel }} 产品资料下载</h2>
            </div>
          </template>

          <!-- 产品信息 -->
          <el-descriptions :column="2" border>
            <el-descriptions-item label="产品型号">{{ selectedCamera.productModel }}</el-descriptions-item>
            <el-descriptions-item label="传感器型号">{{ selectedCamera.sensorModel }}</el-descriptions-item>
            <el-descriptions-item label="分辨率">{{ selectedCamera.resolution }}</el-descriptions-item>
            <el-descriptions-item label="最大帧率">{{ selectedCamera.maxFrameRate }}</el-descriptions-item>
            <el-descriptions-item label="接口类型">{{ selectedCamera.dataInterface }}</el-descriptions-item>
            <el-descriptions-item label="色彩">{{ selectedCamera.colorType }}</el-descriptions-item>
          </el-descriptions>

          <!-- 下载按钮区域 -->
          <div class="download-section">
            <h3>可下载文件</h3>
            <div class="download-items">
              <el-card 
                v-for="(label, type) in downloadTypes" 
                :key="type" 
                class="download-item"
                shadow="hover"
              >
                <div class="file-content">
                  <div class="file-info">
                    <el-icon class="file-icon"><Document /></el-icon>
                    <span class="file-name">{{ label }}</span>
                  </div>
                  <el-button 
                    type="primary" 
                    class="download-btn"
                    @click="downloadFile(type)"
                  >
                    <el-icon><Download /></el-icon>
                    下载
                  </el-button>
                </div>
              </el-card>
            </div>
          </div>
        </el-card>

        <el-empty v-else description="请选择要下载的产品"></el-empty>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Document, Download } from '@element-plus/icons-vue'
import { cameraDatabase } from '@/utils/cameraData'

export default {
  components: {
    Document,
    Download
  },
  data() {
    return {
      currentCameraType: 'area',
      activeCamera: '',
      selectedCamera: null,
      downloadTypes: {
        file: '产品规格书',
        software: '配套软件',
        manual: '用户手册',
        drawing: '结构图纸'
      }
    }
  },
  computed: {
    currentCameras() {
      return cameraDatabase[this.currentCameraType]
    }
  },
  created() {
    // 从 URL 获取相机 ID
    const cameraId = this.$route.query.id
    if (cameraId) {
      this.loadCameraById(cameraId)
    }
  },
  methods: {
    getCameraTypeName(type) {
      const types = {
        standard: '标准相机',
        function: '功能相机',
        ai: 'AI相机'
      }
      return types[type] || type
    },
    handleCameraSelect(id) {
      this.loadCameraById(id)
      // 更新 URL，但不重新加载页面
      this.$router.push({
        query: { id }
      })
    },
    loadCameraById(id) {
      // 在所有相机类型中查找匹配的相机
      for (const type in cameraDatabase) {
        for (const category in cameraDatabase[type]) {
          const camera = cameraDatabase[type][category].find(c => c.id === id)
          if (camera) {
            this.selectedCamera = camera
            this.currentCameraType = type
            this.activeCamera = id
            return
          }
        }
      }
    },
    downloadFile(fileType) {
      if (!this.selectedCamera) return
      
      // 根据文件类型设置文件扩展名
      const fileExtension = fileType === 'software' ? '.zip' : '.pdf'
      const fileName = `${this.selectedCamera.productModel}_${this.downloadTypes[fileType]}${fileExtension}`
      const filePath = `/files/${fileName}`

      fetch(filePath)
        .then(response => {
          if (!response.ok) throw new Error('File not found')
          return response.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$message.error(`${this.downloadTypes[fileType]}暂未上传，请联系工作人员`)
        })
    }
  }
}
</script>

<style scoped>
/* 基础布局 */
.download-container {
  padding: 20px;
  max-width: 1800px;
  margin: 0 auto;
}

.responsive-container {
  display: flex;
  gap: 20px;
}

.responsive-aside {
  width: 300px !important;
  transition: all 0.3s;
}

.responsive-main {
  flex: 1;
  padding: 0;
}

/* 下载项样式优化 */
.download-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* 响应式布局优化 */
@media screen and (max-width: 1400px) {
  .download-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .download-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .download-items {
    grid-template-columns: 1fr;
  }
}

/* 响应式布局调整 */
@media screen and (max-width: 1200px) {
  .download-container {
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .download-container {
    padding: 10px;
  }
  
  /* 控制面板移动端样式 */
  .control-panel {
    flex-direction: column;
    align-items: stretch;
  }
  
  .camera-type-selector {
    width: 100%;
  }
  
  :deep(.el-radio-group) {
    width: 100%;
    display: flex;
  }
  
  :deep(.el-radio-button) {
    flex: 1;
  }
  
  :deep(.el-radio-button__inner) {
    width: 100%;
  }
  
  /* 表格移动端优化 */
  :deep(.el-table) {
    font-size: 14px;
  }
  
  :deep(.el-table__header) th {
    padding: 8px 0;
  }
  
  :deep(.el-table__row) td {
    padding: 8px 0;
  }
  
  /* 下载按钮移动端样式 */
  .download-buttons {
    flex-direction: column;
    gap: 5px;
  }
  
  /* 详情卡片移动端样式 */
  .download-item {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
  
  .file-info {
    justify-content: center;
  }
  
  :deep(.el-descriptions__cell) {
    padding: 12px !important;
  }
  
  :deep(.el-descriptions__label) {
    width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .download-container {
    padding: 5px;
  }
  
  :deep(.el-table) {
    font-size: 13px;
  }
  
  :deep(.el-button--small) {
    padding: 8px 12px;
  }
  
  .file-icon {
    font-size: 20px;
  }
}

/* 下载区域样式 */
.download-section {
  margin-top: 30px;
}

.download-section h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.download-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.download-item {
  height: 100%;
}

.file-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.file-icon {
  font-size: 24px;
  color: #409EFF;
}

.file-name {
  font-size: 16px;
  color: #333;
}

.download-btn {
  width: 100%;
}

/* 响应式调整 */
@media screen and (max-width: 1200px) {
  .download-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .download-items {
    grid-template-columns: 1fr;
  }

  .file-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .download-btn {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .file-content {
    flex-direction: column;
    text-align: center;
  }

  .file-info {
    justify-content: center;
  }

  .download-btn {
    width: 100%;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.radio-group-wrapper {
  margin-left: auto;  /* 将按钮组推到右边 */
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .card-header {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .radio-group-wrapper {
    width: 100%;
    margin-left: 0;
  }

  :deep(.el-radio-group) {
    width: 100%;
    display: flex;
  }

  :deep(.el-radio-button) {
    flex: 1;
  }

  :deep(.el-radio-button__inner) {
    width: 100%;
  }
}
</style> 