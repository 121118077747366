<template>
  <div>
    <el-container v-loading="isLoading"> <!-- 使用Element UI的加载指示器 -->
      <el-header>
        <h1 class="page-title" style="--after-width: 80px;">关于我们</h1>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="hover">
              <h2  class="page-title" style="--after-width: 95px;">公司简介</h2>
              <p>
                深圳市博峤技术有限公司，属珠海博杰集团旗下子公司。 2022年9月成立，位于深圳南山科技园片区方大城。
                汇聚淅大、成电等知名院校图像处理及算法的精英人才，依托博杰集团在机器视觉领域的技术、市场沉淀，持续研发推出专用机器视觉产品。
                产品主要包括工业相机、边缘计算相机、行业专用AI相机及其配套光源等。
              </p>
            </el-card>
          </el-col>
        </el-row>
        <el-row class="responsive-row" :gutter="20" style="margin-top: 20px;">
          <el-col :span="12">
            <el-card shadow="hover">
              <h2  class="page-title" style="--after-width: 95px;">产品分类</h2>
              <h4>三级立体策略，深研行业需求，实现快捷专业定制</h4>
              <ul>
                <li>工业相机(基础通用型)：兼容Basler，海康相机，产品覆盖各种工业制造领域，实现定位追踪、尺寸测量、瑕疵检测、监控等。</li>
                <li>边缘相机(专业领域敏捷应用)：在某些特定场景，不借助外部系统，仅通过相机自身运算，实现过滤及测量。</li>
                <li>AI相机(系统级应用)：利用前端相机和挂载AI算法软件的主控系统，实现专业智能的复杂定位、高精度测量及瑕疵检测等。</li>
              </ul>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card shadow="hover">
              <h3  class="page-title" style="--after-width: 40px;">概述</h3>
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true, // 定义加载状态
    };
  },
  methods: {
    async fetchData() {
      try {
        // 模拟异步数据请求，例如通过axios获取数据
        await new Promise(resolve => setTimeout(resolve, 500));
        // 假设数据请求成功，更新状态
      } catch (error) {
        console.error("数据加载失败:", error);
      } finally {
        this.isLoading = false; // 数据加载完毕，关闭加载状态
      }
    }
  },
  mounted() {
    this.fetchData(); // 在组件挂载时请求数据
  }
};
</script>

<style scoped>
 /* 页面标题样式 */
 .page-title {
    width: 100%;
    margin-bottom: 40px;
    position: relative; /* 相对定位用于伪元素定位 */
    padding-left: 0px; /* 可根据需要调整 padding */
}

/* 伪元素 平行四边形 */
.page-title::after {
    content: '';
    display: block;
    width: var(--after-width); /* 使用自定义属性来动态设置宽度 */
    height: 5px; /* 高度 */
    background-color: #409eff;
    position: absolute;
    left: 0px; /* 保证伪元素与标题左对齐 */
    transform: skewX(-20deg); /* 直接倾斜成平行四边形 */
    bottom: -1px; /* 控制横线到标题的距离 */
}
.responsive-row {
  display: flex;
  flex-wrap: wrap; /* 保证在大屏幕下仍是水平排列 */
}

.responsive-row .el-col {
  flex: 1 1 50%; /* 默认情况下每个卡片占据50%的宽度 */
  max-width: 50%;
  box-sizing: border-box; /* 确保padding和border不会影响宽度 */
}

@media (max-width: 799px) {
  .responsive-row {
    flex-direction: column; /* 小屏幕时卡片垂直排列 */
  }

  .responsive-row .el-col {
    flex: 1 1 100%; /* 小屏幕时每个卡片占据100%的宽度 */
    max-width: 100%; /* 强制最大宽度为100% */
    margin-bottom: 20px; /* 添加卡片之间的底部间距 */
  }
}
</style>