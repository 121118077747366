<template>
  <div class="tech-support-container">
    <!-- 顶部卡片导航 -->
    <div class="category-cards">
      <el-card 
        v-for="(category, index) in categories" 
        :key="index"
        class="category-card"
        :class="{ active: currentCategory === category.key }"
        @click="switchCategory(category.key)"
      >
        <div class="card-content">
          <el-icon class="category-icon" :size="32">
            <component :is="category.icon" />
          </el-icon>
          <h3>{{ category.title }}</h3>
          <p>{{ category.description }}</p>
        </div>
      </el-card>
    </div>

    <!-- 内容显示区域 -->
    <el-card class="content-card">
      <template #header>
        <div class="content-header">
          <h2>{{ getCurrentCategory.title }}</h2>
          <el-input
            v-model="searchText"
            placeholder="搜索相关内容"
            prefix-icon="Search"
            clearable
            class="search-input"
          />
        </div>
      </template>

      <!-- 基础知识内容 -->
      <div v-if="currentCategory === 'basic'" class="content-section">
        <el-collapse>
          <el-collapse-item 
            v-for="(item, index) in filteredBasicKnowledge" 
            :key="index"
          >
            <template #title>
              <div class="collapse-title">
                <span>{{ item.title }}</span>
                <el-button 
                  type="primary" 
                  link
                  class="download-btn"
                  @click.stop="downloadPDF(item)"
                >
                  <el-icon><Download /></el-icon>
                  PDF详情下载
                </el-button>
              </div>
            </template>
            <div class="knowledge-content" v-html="item.content"></div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- 应用技术内容 -->
      <div v-else-if="currentCategory === 'application'" class="content-section">
        <el-collapse>
          <el-collapse-item 
            v-for="(item, index) in filteredApplicationTech" 
            :key="index"
          >
            <template #title>
              <div class="collapse-title">
                <span>{{ item.title }}</span>
                <el-button 
                  type="primary" 
                  link
                  class="download-btn"
                  @click.stop="downloadPDF(item)"
                >
                  <el-icon><Download /></el-icon>
                  PDF详情下载
                </el-button>
              </div>
            </template>
            <div class="knowledge-content">
              <p>{{ item.description }}</p>
              <div v-if="item.detail" class="detail-content" v-html="item.detail"></div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- 问题解决内容 -->
      <div v-else-if="currentCategory === 'solution'" class="content-section">
        <el-table :data="filteredSolutions" style="width: 100%">
          <el-table-column prop="problem" label="常见问题" width="300" />
          <el-table-column prop="solution" label="解决方案" />
          <el-table-column fixed="right" label="操作" width="120">
            <template #default="scope">
              <el-button 
                link 
                type="primary" 
                @click="showSolutionDetail(scope.row)"
              >
                详细步骤
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 详情对话框 -->
    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="60%"
      destroy-on-close
    >
      <div v-html="dialogContent"></div>
    </el-dialog>
  </div>
</template>

<script>
import { Download } from '@element-plus/icons-vue'

export default {
  name: 'TechSupport',
  components: {
    Download
  },
  data() {
    return {
      currentCategory: 'basic',
      searchText: '',
      dialogVisible: false,
      dialogTitle: '',
      dialogContent: '',
      categories: [
        {
          key: 'basic',
          title: '基础知识',
          description: '相机基本原理与概念',
          icon: 'Reading'
        },
        {
          key: 'application',
          title: '应用技术',
          description: '实际应用案例与技术',
          icon: 'Connection'
        },
        {
          key: 'solution',
          title: '问题解决',
          description: '常见问题与解决方案',
          icon: 'Service'
        }
      ],
      basicKnowledge: [
        {
          title: '什么是机器视觉？',
          content: '机器视觉是...<详细内容>',
          pdfUrl: '/files/machine-vision-intro.pdf'
        },
        {
          title: '相机参数详解',
          content: '相机的主要参数包括...<详细内容>',
          pdfUrl: '/files/camera-params.pdf'
        }
        // 更多基础知识条目
      ],
      applicationTech: [
        {
          title: '工业缺陷检测应用',
          description: '使用机器视觉进行产品缺陷检测的案例分析。通过高精度相机采集图像，结合深度学习算法，实现自动化质量检测。',
          detail: `
            <h4>应用场景</h4>
            <p>主要应用于电子元件、PCB板、包装等产品的表面缺陷检测。</p>
            
            <h4>技术方案</h4>
            <ul>
              <li>采用高分辨率工业相机</li>
              <li>使用定制光源方案</li>
              <li>深度学习检测算法</li>
            </ul>
            
            <h4>实施效果</h4>
            <p>检测准确率达99.9%，处理速度提升300%。</p>
          `,
          pdfUrl: '/files/defect-detection.pdf'
        },
        {
          title: '尺寸测量应用',
          description: '基于机器视觉的高精度尺寸测量解决方案',
          detail: `
            <h4>应用场景</h4>
            <p>适用于精密零件、模具等产品的尺寸测量。</p>
            
            <h4>核心技术</h4>
            <p>亚像素边缘检测、多点标定等。</p>
          `,
          pdfUrl: '/files/dimension-measurement.pdf'
        }
        // 可以添加更多应用技术案例
      ],
      solutions: [
        {
          problem: '相机无法连接',
          solution: '检查网络连接和电源供应',
          detail: '<详细步骤说明>'
        }
        // 更多解决方案条目
      ]
    }
  },
  computed: {
    getCurrentCategory() {
      return this.categories.find(c => c.key === this.currentCategory) || this.categories[0]
    },
    filteredBasicKnowledge() {
      return this.basicKnowledge.filter(item => 
        this.searchText ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) : true
      )
    },
    filteredApplicationTech() {
      return this.applicationTech.filter(item =>
        this.searchText ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) : true
      )
    },
    filteredSolutions() {
      return this.solutions.filter(item =>
        this.searchText ? 
          item.problem.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.solution.toLowerCase().includes(this.searchText.toLowerCase())
        : true
      )
    }
  },
  methods: {
    switchCategory(category) {
      this.currentCategory = category
    },
    showDetail(item) {
      this.dialogTitle = item.title
      this.dialogContent = item.detail
      this.dialogVisible = true
    },
    showSolutionDetail(solution) {
      this.dialogTitle = solution.problem
      this.dialogContent = solution.detail
      this.dialogVisible = true
    },
    downloadPDF(item) {
      if (!item.pdfUrl) {
        this.$message.warning('PDF文档暂未上传，请联系工作人员')
        return
      }

      fetch(item.pdfUrl)
        .then(response => {
          if (!response.ok) throw new Error('File not found')
          return response.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${item.title}.pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {
          this.$message.error('下载失败，请稍后重试')
        })
    }
  }
}
</script>

<style scoped>
.tech-support-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.category-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.category-card {
  cursor: pointer;
  transition: all 0.3s;
}

.category-card:hover {
  transform: translateY(-5px);
}

.category-card.active {
  border: 2px solid var(--el-color-primary);
}

.card-content {
  text-align: center;
  padding: 20px;
}

.category-icon {
  font-size: 32px;
  color: var(--el-color-primary);
  margin-bottom: 15px;
}

.card-content h3 {
  margin: 10px 0;
  color: var(--el-text-color-primary);
}

.card-content p {
  color: var(--el-text-color-secondary);
  font-size: 14px;
}

.content-card {
  margin-top: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 300px;
}

.content-section {
  min-height: 400px;
}

.knowledge-content {
  padding: 10px;
  line-height: 1.6;
}

/* 响应式布局 */
@media screen and (max-width: 1200px) {
  .category-cards {
    gap: 15px;
  }
  
  .search-input {
    width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .category-cards {
    grid-template-columns: 1fr;
  }
  
  .content-header {
    flex-direction: column;
    gap: 15px;
  }
  
  .search-input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .tech-support-container {
    padding: 10px;
  }
  
  .card-content {
    padding: 15px;
  }
}

/* 添加新的样式 */
.collapse-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.download-btn {
  margin-left: 15px;
  font-size: 14px;
}

.download-btn :deep(.el-icon) {
  margin-right: 4px;
}

/* 确保按钮在移动端也能正常显示 */
@media screen and (max-width: 480px) {
  .collapse-title {
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .download-btn {
    margin-left: 0;
  }
}

.date-text {
  font-size: 13px;
  color: var(--el-text-color-secondary);
  margin-left: 15px;
}

.knowledge-content {
  padding: 15px;
}

.knowledge-content p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.detail-content {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--el-border-color-lighter);
}
</style> 