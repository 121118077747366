<!-- src/views/Subscribe.vue -->
<!-- 订阅功能最终目标要实现点击订阅跳转到支付界面用户付款后返回收款id -->
<template>
  <!-- <div>
    <el-container>
      <el-header>
        <h1>订阅服务</h1>
      </el-header>
      <el-main>
        <el-row class="card-sub" :gutter="20">
          <el-col :span="8">
            <el-card shadow="hover">
              <h3>基础版</h3>
              <p>￥99/月</p>
              <p>适合个人用户，包含所有基本功能。</p>
              <el-button type="primary" @click="subscribe('基础版')">订阅</el-button>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover">
              <h3>高级版</h3>
              <p>￥199/月</p>
              <p>适合小型企业用户，包含高级功能。</p>
              <el-button type="primary" @click="subscribe('高级版')">订阅</el-button>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover">
              <h3>企业版</h3>
              <p>￥299/月</p>
              <p>适合大型企业用户，包含所有功能。</p>
              <el-button type="primary" @click="subscribe('企业版')">订阅</el-button>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div> -->
  <h1 class="page-title" style="--after-width: 240px;">暂未开放，尽情期待</h1>
</template>
<style scoped>
/* 标题样式 */
.page-title {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    position: relative; /* 相对定位用于伪元素定位 */
    padding-left: 0px; /* 可根据需要调整 padding */
}

/* 伪元素 平行四边形 */
.page-title::after {
  content: '';
  display: block;
  width: var(--after-width); /* 使用自定义属性来动态设置宽度 */
  height: 6px; /* 高度 */
  background-color: #409eff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) skewX(-20deg); /* 水平居中旋转成平行四边形 */
  bottom: -1px; /* 控制横线到标题的距离 */
}
.card-sub {
  display: flex;
  flex-wrap: wrap; /* 保证在大屏幕下仍是水平排列 */
  justify-content: space-between;
}

.card-sub .el-col {
  display: flex;
  flex-direction: column; /* 使卡片在el-col中垂直排列 */
  align-items: center; /* 使内容在卡片中居中 */
  flex: 1 1 30%; /* 默认情况下每个卡片占据30%的宽度 */
  max-width: 30%;
  box-sizing: border-box; /* 确保padding和border不会影响宽度 */
  margin-bottom: 20px; /* 添加卡片之间的底部间距 */
}

.card-sub .el-card {
  display: flex;
  flex-direction: column; /* 使卡片内容垂直排列 */
  justify-content: space-between; /* 保证内容之间的均匀间距 */
  width: 100%; /* 确保卡片占满列宽度 */
  height: 100%; /* 确保卡片占满列高度 */
}

/* 小屏幕时，卡片垂直排列 */
@media (max-width: 699px) {
  .card-sub {
    flex-direction: column; /* 小屏幕时卡片垂直排列 */
    align-items: center; /* 卡片居中对齐 */
  }

  .card-sub .el-col {
    flex: 1 1 auto; /* 小屏幕时每个卡片占据自动高度 */
    max-width: 100%; /* 强制最大宽度为100% */
    margin-bottom: 20px; /* 保持卡片之间的底部间距 */
  }
  
  .card-sub .el-card {
    width: 100%; /* 小屏幕时卡片占满父容器宽度 */
  }
}
</style>