<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2 class="login-title">{{ isLogin ? '用户登录' : '用户注册' }}</h2>
      <el-form :model="form" :rules="rules" ref="form" @submit.prevent="submitForm" label-width="120px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username" placeholder="请输入您的用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password" placeholder="请输入您的密码"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="确认密码" prop="confirmPassword">
          <el-input type="password" v-model="form.confirmPassword" placeholder="请再次输入您的密码"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="电话号码" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入您的电话号码"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
        </el-form-item>
        <el-form-item v-if="!isLogin" label="产品组" prop="productGroup">
          <el-select v-model="form.productGroup" placeholder="请选择产品组">
            <el-option label="产品组A" value="A"></el-option>
            <el-option label="产品组B" value="B"></el-option>
            <el-option label="产品组C" value="C"></el-option>
          </el-select>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item label="验证码" prop="captcha">
          <el-row>
            <el-col :span="14">
              <el-input v-model="form.captcha" placeholder="请输入验证码"></el-input>
            </el-col>
            <el-col :span="10">
              <div class="captcha" @click="refreshCaptcha">{{ captcha }}</div>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- 同意隐私政策和服务协议 -->
        <el-form-item prop="agreeToTerms">
          <el-checkbox v-model="form.agreeToTerms">
            我已阅读并同意
            <a href="/PrivacyPolicy" target="_blank">隐私政策</a> 和
            <a href="/ServiceAgreement" target="_blank">服务协议</a>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">{{ isLogin ? '登录' : '注册' }}</el-button>
          <el-button type="text" @click="toggleForm">{{ isLogin ? '没有账号？注册' : '已有账号？登录' }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import total from './total.js';

export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
        confirmPassword: '',
        phone: '',
        email: '',
        productGroup: '',
        captcha: '',
        agreeToTerms: false
      },
      isLogin: true,
      captcha: '', // 验证码
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 15, message: '用户名长度在5到15个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在6到20个字符', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (!/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
                callback(new Error('密码必须包含大小写字母'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error('两次输入的密码不一致'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的电话号码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入有效的邮箱地址', trigger: 'blur' }
        ],
        productGroup: [
          { required: true, message: '请选择产品组', trigger: 'change' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value.toLowerCase() !== this.captcha.toLowerCase()) {
                callback(new Error('验证码错误'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        agreeToTerms: [
          { required: true, message: '请阅读并同意隐私政策和服务协议', trigger: 'change' }
        ]
      }
    };
  },
  created() {
    this.generateCaptcha();
  },
  methods: {
    toggleForm() {
      this.isLogin = !this.isLogin;
      this.$refs.form.resetFields();
      this.generateCaptcha();
    },
    generateCaptcha() {
      this.captcha = Math.random().toString(36).substring(2, 6).toUpperCase();
    },
    refreshCaptcha() {
      this.generateCaptcha();
    },
    async submitForm() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        const url = this.isLogin ? `${total.BASEURL}/api/login/` : `${total.BASEURL}/api/register/`;
        try {
          const response = await axios.post(url, this.form, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200 || response.status === 201) {
            this.$message.success(this.isLogin ? '登录成功' : '注册成功');
            if (this.isLogin) {
              const { access, refresh, username } = response.data;
              localStorage.setItem('access_token', access);
              localStorage.setItem('refresh_token', refresh);
              localStorage.setItem('username', username);
              axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
              this.$router.push('/user');
              this.$store.dispatch('checkAuthentication');
            } else {
              this.isLogin = true;
            }
          } else {
            this.$message.error(response.data.message || (this.isLogin ? '登录失败' : '注册失败'));
          }
        } catch (error) {
          if (error.response) {
            this.$message.error(error.response.data.message || '请求失败，请稍后再试');
          } else {
            this.$message.error('请求失败，请稍后再试');
          }
        }
      }
    });
  }
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  width: 400px;
  padding: 20px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.captcha {
  background-color: #f2f2f2;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.el-form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form-item .el-form-item__label {
  width: 120px;
  text-align: right;
}

.el-form-item .el-form-item__content {
  flex: 1;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group el-button {
  width: 48%;
}
</style>